import React, { useState, useEffect } from 'react';
import LocationSearch from '../steps/propertySteps/Location/LocationSearch';
import { RxCross2 } from 'react-icons/rx';
// import LeavePageWarning from './LeavePageWarning';
import { useNavigate } from 'react-router-dom';
import Loader from './Loader';
import { updatePreferredAreas } from '../../services/auth/userService';
import { useAuth } from '../../contexts/auth';
import { initialFilter } from '../../initialStates/initialStates';
import { getAllProperties } from '../../services/propertyService';
import { MarketFilter } from '../../types/types';

export const CitySelector = ({
  inputValue,
  setInputValue,
  selectedCities,
  setSelectedCities,
}: any) => {
  const [formattedText, setFormattedText] = useState('');
  const [errorMessage, setErrorMessage] = useState(''); // Added state for error message
  const [cityCounts, setCityCounts] = useState<{ [city: string]: number }>({});

  const handleAddCity = async () => {
    if (inputValue.trim() && !selectedCities.includes(inputValue)) {
      setSelectedCities([...selectedCities, formattedText]);
      setInputValue(''); // Clear input after adding
      setErrorMessage(''); // Clear error message on successful addition
    }
  };

  const handleRemoveCity = async (city: string) => {
    setErrorMessage(''); // Clear error message on successful addition
    setSelectedCities(selectedCities.filter((c: any) => c !== city));

    // Remove city from the count list
    setCityCounts((prevCounts) => {
      const updatedCounts = { ...prevCounts };
      delete updatedCounts[city];
      return updatedCounts;
    });
  };

  // Fetch the property count for each city
  const fetchCityCount = async (city: string) => {
    const filterWithCity: MarketFilter = { ...initialFilter, city };
    try {
      const response = await getAllProperties(filterWithCity, 1);
      let totalCount = response.data.pagination.totalCount;
      totalCount = totalCount > 999 ? '+999' : totalCount;
      // Update the city count state
      setCityCounts((prevCounts) => ({
        ...prevCounts,
        [city]: totalCount,
      }));
    } catch (error: any) {
      console.error('Error fetching count:', error);
    }
  };

  useEffect(() => {
    if (selectedCities.length >= 5) {
      // Check if already 5 cities
      setErrorMessage('You can only add up to 5 cities.'); // Set error message
      return;
    }
    handleAddCity();
  }, [formattedText]);

  useEffect(() => {
    // Fetch property count for each city when selectedCities changes
    selectedCities.forEach((city: string) => {
      if (!cityCounts[city]) {
        fetchCityCount(city); // Fetch the count only if it hasn't been fetched before
      }
    });
  }, [selectedCities]);

  return (
    <div className="w-72">
      {errorMessage && (
        <div className="text-red-500 text-sm text-center pb-2 mt-[-2]">{errorMessage}</div>
      )}
      <div className=" flex border border-deehiy rounded w-full p-2 bg-white">
        <LocationSearch
          hasOnlyCity={true}
          label=""
          locationString={inputValue}
          setFormattedMainText={setFormattedText}
          setLocationString={setInputValue}
          // setLocationObject={setLocationObject}
        />
      </div>
      <div className=" flex flex-col justify-start w-full mt-2 h-52">
        <div className="text-sm pt-3 pb-1">Your cities:</div>
        {selectedCities.map((city: string, index: number) => (
          <div key={index}>
            <div className=" flex justify-between text-sm text-deehiy pr-2 py-1 border-none items-center">
              <div className=" line-clamp-1 w-56">{city}</div>
              <div className="flex items-center justify-end w-40">
                <span>
                  {cityCounts[city] !== undefined ? `(${cityCounts[city]} listings)` : '- - -'}
                </span>
                <button
                  className="rounded-full hover:bg-gray-100 ml-3"
                  onClick={() => handleRemoveCity(city)}
                >
                  <RxCross2 className="text-lg" />
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const CityPicker = ({ role }: any) => {
  const { currentUser } = useAuth();
  const [inputValue, setInputValue] = useState('');
  const [selectedCities, setSelectedCities] = useState<string[]>([]);
  // const [formattedText, setFormattedText] = useState('');
  // const [locationObject, setLocationObject] = useState({})
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  // const handleAddCity = async () => {
  //   if (inputValue.trim() && !selectedCities.includes(inputValue)) {
  //     setSelectedCities([...selectedCities, formattedText]);
  //     setInputValue(''); // Clear input after adding
  //   }
  // };

  // const handleRemoveCity = async (city: string) => {
  //   setSelectedCities(selectedCities.filter((c) => c !== city));
  // };

  const handleStart = async () => {
    setIsLoading(true);
    const citiesParam = encodeURIComponent(selectedCities.join(','));

    const selectedCitiesJson = JSON.stringify(selectedCities);

    await updatePreferredAreas(currentUser?.id, selectedCitiesJson)
      .catch((e: any) => {
        alert(e.message);
      })
      .finally(() => {
        setIsLoading(false);
      });

    switch (role) {
      case 'buyer':
        navigate(`/?t=buyer&location=${citiesParam}`);
        break;
      case 'renter':
        navigate(`/?t=renter&location=${citiesParam}`);
        break;
      case 'seller':
        navigate('/users/dashboard');
        break;
      case 'landlord':
        navigate('/users/dashboard');
        break;
      case 'agent':
        navigate('/users/dashboard');
        break;
      default:
        navigate('/');
        break;
    }
  };

  // useEffect(() => {
  //   if (selectedCities.length > 5) {
  //     return;
  //   }
  //   handleAddCity();
  // }, [formattedText]);

  return (
    <div className="flex justify-center items-center pt-24">
      <div className="flex mx-auto space-y-8 items-center justify-center flex-col">
        {role === 'renter' || role === 'buyer' ? (
          <>
            <h1 className="text-xl sm:text-2xl text-center">
              Where are you looking to buy or rent?
            </h1>
            <div className="text-base sm:text-xl px-8">
              {' '}
              Let us know your preferred areas to help match you with the perfect property.
            </div>
          </>
        ) : (
          <>
            <h1 className="text-xl sm:text-2xl text-center">Where do you sell or rent out?</h1>
            <div className="text-base sm:text-xl px-8">
              {' '}
              Select up to 5 cities, and our AI will match you with the best leads.
            </div>
          </>
        )}

        <div className="text-xs">You can select multiple cities (5 max).</div>
        {/* <div className="w-72">
          <div className=" flex border border-deehiy rounded w-full p-2">
            <LocationSearch
              hasOnlyCity={true}
              label=""
              locationString={inputValue}
              setFormattedMainText={setFormattedText}
              setLocationString={setInputValue}
              // setLocationObject={setLocationObject}
            />
          </div>
          <div className=" flex flex-col justify-start w-full mt-4 h-64">
            <div className="text-sm py-3">Your cities:</div>
            {selectedCities.map((city: string, index: number) => (
              <div key={index}>
                <div className=" flex justify-between text-sm text-deehiy pr-2 py-2 border-none">
                  <span>{city}</span>
                  <button
                    className="rounded-full hover:bg-gray-100 ml-3"
                    onClick={() => handleRemoveCity(city)}
                  >
                    <RxCross2 />
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div> */}
        <CitySelector
          inputValue={inputValue}
          selectedCities={selectedCities}
          setSelectedCities={setSelectedCities}
          setInputValue={setInputValue}
        />

        {/* <LeavePageWarning /> */}
        {/* <button onClick={() => setStep(0)}>skip</button> */}
        <button
          className="form-next-btn mx-auto text-center justify-center items-center flex-col flex space-y-4 w-48 p-3"
          onClick={handleStart}
          disabled={isLoading}
        >
          Let's start
        </button>
        {isLoading && <Loader />}
      </div>
    </div>
  );
};

export default CityPicker;
