import React, { useState, useEffect } from 'react';
import { useAuth } from '../../contexts/auth';
import { useBusiness } from '../../contexts/business';
import { useInternationalization } from '../../contexts/internationalization';
import Loader from '../main/Loader';
import { Business, Property } from '../../types/types';
import { useParams, useNavigate } from 'react-router-dom';
import PropertyCard from '../main/PropertyCard';
import TopMobileNavigation from '../main/navigation/TopMobileNavigation';
// import AppointAgentBtn from '../listing/AppointAgentBtn';
// import { sendAppointAgentToAdmin } from '../../services/auth/userService';
import { ToastContainer, Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReadMore from '../listing/ReadMore';
import { FiChevronDown } from 'react-icons/fi';
import { Dropdown } from 'flowbite-react';
import { BsFillShareFill } from 'react-icons/bs';
// import { IoOpenOutline } from 'react-icons/io5';
import { useTranslation } from 'react-i18next';
import { getPublicPropertiesByBusinessIdService } from '../../services/businessService';

import Share from '../main/Share';
import { adjustImageSize } from '../../utils/adjustImageSize';

import { useChat } from '../../contexts/chat';

const BusinessPage = () => {
  const [business, setBusiness] = useState<null | Business>(null);
  const [properties, setProperties] = useState<any>(null);
  const { currentUser } = useAuth();
  const { fetchBusinessByIdContext } = useBusiness();
  const { currency } = useInternationalization();
  const [isLoading, setIsLoading] = useState(false);
  // const [isDisabled, setIsDisabled] = useState(false);
  // const [isSendingRequest, setIsSendingRequest] = useState(false);
  const [count, setCount] = useState(0);
  const [pageIndex, setPageIndex] = useState(1);
  const { id } = useParams();
  const { addToContact, chatHandler } = useChat();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleShowMore = () => {
    if (count === properties.length) {
      return;
    }
    setPageIndex((prev) => prev + 1);
  };

  const chatButtonHandler = async () => {
    if (!currentUser?.id) navigate('/login', { state: { redirectTo: location } });

    if (business?.adminId) {
      await chatHandler(business?.adminId, '');
    } else {
    }
  };

  const addContactHandler = async () => {
    if (currentUser?.id && business?.adminId) {
      await addToContact(business?.adminId);
    }
  };

  // const handleAppointAgent = async () => {
  //   if (!currentUser?.id) {
  //     toast.info('Please, log in to save this property');
  //     return;
  //   }
  //   setIsSendingRequest(true);
  //   const userId = currentUser?.id;
  //   const businessId = business?.id;
  //   try {
  //     if (userId && businessId) await sendAppointAgentToAdmin(userId, businessId);
  //     setIsDisabled(true);
  //     toast.success('Appoint business request sent!');
  //   } catch (err: any) {
  //     toast.error('oops, something went wrong');
  //     throw new Error(err);
  //   } finally {
  //     setIsSendingRequest(false);
  //   }
  // };

  useEffect(() => {
    setIsLoading(true);

    fetchBusinessByIdContext(Number(id)).then((data: Business) => {
      console.log(data);
      setBusiness(data);
      setIsLoading(false);
    });
  }, [id]);

  useEffect(() => {
    if (business?.businessId) {
      const limit = 3;
      getPublicPropertiesByBusinessIdService(business?.businessId, currency, pageIndex, limit).then(
        (res: any) => {
          const newProperties = res.data?.properties || [];

          if (pageIndex !== 1) {
            setProperties([...properties, ...newProperties]);
          } else {
            setProperties(newProperties);
          }
          setCount(res.data?.total || 0);
        }
      );
    }
  }, [pageIndex]);

  useEffect(() => {
    if (business?.businessId) {
      const limit = 3;

      if (pageIndex === 1) {
        getPublicPropertiesByBusinessIdService(business?.businessId, currency, 1, limit).then(
          (res: any) => {
            const newProperties = res.data?.properties || [];

            setProperties([...newProperties]);
            setCount(res.data?.total || 0);
          }
        );
      } else {
        setPageIndex(1);
      }
    }
  }, [business, currency]);

  if (isLoading) return <Loader />;

  return (
    <>
      <TopMobileNavigation />
      <div className="xl:max-w-[1430px] lg:max-w-[1240px] container mx-auto  py-2 sm:py-0 px-0 sm:px-0 md:px-4">
        <div className="flex flex-col sm:flex-row text-sm sm:text-base">
          <div className="flex-col w-full sm:w-1/4 px-4 sm:px-4  pt-12 sm:pt-12">
            <div className="flex mx-auto flex-col space-y-2 border  border-transparent sm:border-deehiy rounded-2xl shadow-none sm:shadow-2xl p-0 sm:p-8">
              <div className="relative w-32 flex mx-auto">
                <img
                  src={business?.image && adjustImageSize(business?.image)}
                  alt=""
                  className="w-28 h-28 rounded-full mx-auto"
                />
              </div>
              <div className="flex text-sm space-x-6 mx-auto pt-6 font-semibold items-baseline gap-2">
                {business?.businessName}
              </div>
              <div className="flex-col text-sm space-y-4">
                <div className="flex flex-col">
                  <span>{t('active in')}</span>
                  <span className="font-bold">
                    {business?.city && business?.country && (
                      <span>{`${business.city}, ${business.country}`}</span>
                    )}
                    {business?.city && !business?.country && <span>{business.city}</span>}
                    {!business?.city && business?.country && <span>{business.country}</span>}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span>{t('joined')}</span>
                  <span className="font-bold">
                    {business?.createdAt &&
                      new Date(business?.createdAt).toLocaleString('en-US', {
                        month: 'long',
                        year: 'numeric',
                      })}
                  </span>
                </div>
                <div className="flex flex-col">
                  <span>{t('business category')}</span>
                  <div className="font-bold">{business?.businessCategory}</div>
                </div>
              </div>
              {/* {business?.isDedicatedProfile && (
                <AppointAgentBtn
                  handleAppointAgent={handleAppointAgent}
                  isDisabled={isDisabled}
                  isSendingRequest={isSendingRequest}
                />
              )} */}
              {currentUser?.id !== business?.adminId && (
                <div className="hidden sm:flex  w-full justify-evenly items-center py-4 pt-8 space-x-2">
                  <div>
                    <button
                      className="primary-btn justify-start flex gap-x-2"
                      onClick={addContactHandler}
                    >
                      <img src="/images/icons/business/add_member_white.svg" className="h-5 w-5" />{' '}
                      {t('Add')}
                    </button>
                  </div>
                  <div>
                    <button className="primary-btn flex gap-x-2" onClick={chatButtonHandler}>
                      <img src="/images/icons/chat.svg" className="h-5 w-5" /> {t('Chat')}
                    </button>
                  </div>
                </div>
              )}

              {/* {business?.isWhiteLabel && (
                <div className="flex items-center w-full justify-center text-gray-400 gap-x-2">
                  <span className="text-sm">White Label Market</span>
                  <div
                    className="cursor-pointer flex items-center "
                    title="Open market"
                    onClick={() => {
                      window.open(
                        `${process.env.REACT_APP_BASE_URL_PROD}/business/${business.businessId}/market`,
                        '_blank'
                      );
                    }}
                  >
                    <div className="cursor-pointer hover:text-deehiy flex text-gray-400 text-sm sm:text-sm gap-x-2 justify-center items-center">
                      <IoOpenOutline className="my-auto w-3 h-3 sm:w-4 sm:h-4" />
                    </div>
                  </div>

                  <div className="flex my-auto">
                    <Dropdown
                      arrowIcon={false}
                      inline={true}
                      placement="bottom-end"
                      label={
                        <div className=" hover:text-deehiy flex  text-gray-400 text-sm sm:text-sm gap-x-2 justify-center items-center">
                          <BsFillShareFill className="my-auto w-3 h-3 sm:w-3 sm:h-3" />
                        </div>
                      }
                    >
                      <Dropdown.Header>
                        <Share type="market" id={business?.businessId!} />
                      </Dropdown.Header>
                    </Dropdown>
                  </div>
                </div>
              )} */}
            </div>
          </div>
          <div className="flex-col w-full sm:w-3/4 px-4 sm:px-12 pt-4 sm:pt-12 space-y-8">
            <div className="hidden sm:flex text-3xl sm:text-5xl font-bold">
              {' '}
              {t('Hi! Thanks for passing by.')}
            </div>
            <div className="text-xl font-bold flex">
              {' '}
              {t('About')} {''}
              {business?.businessName}
              <div className="flex my-auto">
                <Dropdown
                  arrowIcon={false}
                  inline={true}
                  placement="bottom-end"
                  label={
                    <div className=" hover:text-deehiy flex ml-2 text-gray-400 text-sm sm:text-lg gap-3 justify-center items-center">
                      <BsFillShareFill className="my-auto w-4 h-4 sm:w-4 sm:h-4" />
                      <span className="hidden sm:flex">{t('share')}</span>{' '}
                    </div>
                  }
                >
                  <Dropdown.Header>
                    <Share type="business" id={business?.businessId!} />
                  </Dropdown.Header>
                </Dropdown>
              </div>
            </div>
            <div className="text-sm whitespace-pre-wrap text-justify">
              <ReadMore max={800}>{business?.description ? business?.description : ''}</ReadMore>
            </div>
            {properties?.length > 0 && (
              <div className="pb-6">
                <div className="text-xl font-bold pb-4">
                  {t('user_listings', { name: business?.businessName })}
                </div>

                <div className="gap-4 grid grid-cols-1 sm:grid-cols-3">
                  {properties.map((property: Property, index: number) => (
                    <PropertyCard property={property} key={index} />
                  ))}
                </div>

                {count > properties.length && (
                  <div className="flex justify-end font-bold text-deehiy text-xs pb-20 sm:pb-8 sm:my-4">
                    <button
                      onClick={handleShowMore}
                      className=" justify-center items-center flex space-x-2"
                    >
                      <div className=" hover:animate-bounce text-deehiy rounded-full border border-deehiy p-1">
                        <FiChevronDown className="w-5 h-5" />
                      </div>
                      <span>{t('show more')}</span>
                    </button>
                  </div>
                )}
              </div>
            )}

            <hr className="hidden sm:flex border border-deehiy" />
          </div>
        </div>
      </div>
      <ToastContainer transition={Slide} />
    </>
  );
};

export default BusinessPage;
