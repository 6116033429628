import React, { useRef, useState, useEffect } from 'react';
import PropertyCard from '../PropertyCard';
import { useProperty } from '../../../contexts/property';
import { useTranslation } from 'react-i18next';
import { useGlobal } from '../../../contexts/global';
import InfiniteScroll from 'react-infinite-scroll-component';
import PromotedCard from '../PromotedCard';
import Loader from '../Loader';
import HuntCard from '../HuntCard';
import Modal2 from '../../listing/Modal2';
import HuntModalContent from '../HuntModalContent';

const PropertiesGrid: React.FC<any> = () => {
  const { debouncedFetchProperties, pageIndex, properties, filter, hasMore } = useProperty();
  const { t } = useTranslation();
  const { isWL } = useGlobal();

  const listRef = useRef<HTMLDivElement>(null);

  const [showModal, setShowModal] = useState(false);
  const popupPageIndexThreshold = window.innerWidth <= 768 ? 3 : 4;

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem('huntModShown');

    console.log('hasModalBeenShown', hasModalBeenShown);

    if (hasModalBeenShown === 'true') {
      console.log('hasModalBeenShown true', hasModalBeenShown);
      setShowModal(false);
    }
  }, []);

  useEffect(() => {
    const hasModalBeenShown = sessionStorage.getItem('huntModShown');

    // Only show the modal if it hasn't been shown yet
    if (pageIndex === popupPageIndexThreshold && !showModal && hasModalBeenShown !== 'true') {
      // Show the popup
      setShowModal(true);
      // Set session storage to prevent reopening
      sessionStorage.setItem('huntModShown', 'true');
    }
  }, [pageIndex, showModal]);

  if (!properties || properties.length === 0)
    return (
      <div className="text-center p-12 text-sm text-gray-500">
        {t('Looks like we ran out of options. Try changing your criteria.')}
      </div>
    );

  return (
    <div ref={listRef}>
      <InfiniteScroll
        dataLength={properties.length}
        next={() => debouncedFetchProperties(filter, pageIndex, properties)}
        hasMore={hasMore}
        loader={
          <div className="fixed bottom-36 inset-x-0">
            <Loader />
          </div>
        }
        endMessage={<div className="block text-gray-400 pt-12"></div>}
        className="flex flex-wrap xl:gap-4 md:gap-3 px-0 place-content-center sm:px-0 py-4 pb-12"
        style={{ overflow: 'hidden' }}
      >
        {properties?.map((item: any, index: number) => {
          if (
            isWL &&
            (item?.type === 'promotedCard' ||
              item?.type === 'promotedListing' ||
              item?.type === 'huntAd')
          ) {
            return null; // Skip the item for WL
          }
          if (item?.type && item?.type === 'huntAd') {
            return <HuntCard key={`hunt-${index}`} />;
          } else if (item?.type && item?.type === 'promotedCard') {
            return <PromotedCard key={`promotedCard-${index}`} card={item} />;
          } else if (item?.type && item?.type === 'promotedListing') {
            return <PropertyCard key={`promotedListing-${index}`} property={item} isAd={true} />;
          } else {
            return <PropertyCard key={item.propertyId} property={item} />;
          }
        })}
      </InfiniteScroll>
      <Modal2 showModal={showModal} setShowModal={setShowModal}>
        <HuntModalContent setShowModal={setShowModal} />
      </Modal2>
    </div>
  );
};

export default PropertiesGrid;
