import React, { useState, useEffect } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Protected from './Protected';
import AdminProtected from './AdminProtected';
import BusinessProtected from './BusinessProtected';
import DashboardLayout from '../components/Layouts/DashboardLayout';
// signed
import ManageListingPage from '../pages/ManageListingPage';
// public
import AddProperty from '../pages/AddProperty';
import Home from '../pages/market/MarketPage';
import LoginPage from '../pages/LoginPage';
import RegisterPage from '../pages/RegisterPage';
import UpdatePasswordPage from '../pages/UpdatePasswordPage';
import ResetPasswordPage from '../pages/ResetPasswordPage';
import ListingPage from '../pages/ListingPage';
import PreviewListingPage from '../pages/PreviewListingPage';
import Layout from '../components/Layouts/Layout';
import CustomLayout from '../components/Layouts/CustomLayout';
import ListingGuidelines from '../pages/info/ListingGuidelinesPage';
import TermsOfService from '../pages/info/TermsOfServicePage';
import CustomURLPolicy from '../pages/info/CustomURLPolicy';
import ContentPolicy from '../pages/info/ContentPolicy';
import Listing from '../pages/Listing';
import PrivacyPolicy from '../pages/info/PrivacyPolicy';
import AboutUs from '../pages/info/AboutUs';
// import CommunityLayout from '../components/Layouts/CommunityLayout';
// import CommunityCenterHomePage from '../pages/info/CommunityCenterHomePage';
// import LearningHomePage from '../pages/info/LearningHomePage';
import HelpCenterHomePage from '../pages/info/HelpCenterHomePage';
import HelpCenterLayout from '../components/Layouts/HelpCenterLayout';
import Article from '../components/helpCenter/Article';
import LearningCenterLayout from '../components/Layouts/LearningCenterLayout';
import ComingSoonPage from '../pages/info/ComingSoonPage';
import EditListingPage from '../pages/EditListingPage';
import VerifyProfile from '../components/dashboard/VerifyProfile';
import SalesPreLaunch from '../pages/info/SalesPreLaunch';
import FormsPreLaunch from '../pages/info/FormsPreLaunch';
import DashboardHomePage from '../pages/DashboardHomePage';
import HuntingPreLaunch from '../pages/info/HuntingPreLaunch';
import NetworkPreLaunch from '../pages/info/NetworkPreLaunch';
import AgentPage from '../pages/AgentPage';
import LoginAndSecurityPage from '../pages/LoginAndSecurityPage';
import NotFound from '../pages/info/NotFound';
import ProfilePage from '../pages/ProfilePage';
import DeleteAccount from '../components/dashboard/DeleteAccount';
import Notifications from '../components/dashboard/Notifications';
import AdminDashboardHomePage from '../components/dashboard/adminDashboard/AdminHomepageDashboard';
import AdminManageListings from '../components/dashboard/adminDashboard/AdminManageListings';
import AdminEditListing from '../components/dashboard/adminDashboard/AdminEditListing';
import AdminManageUsers from '../components/dashboard/adminDashboard/AdminManageUsers';
import AdminManagePosts from '../components/dashboard/adminDashboard/AdminManagePosts';
import CreatedSuccessfully from '../components/steps/CreatedSuccessfully';
import LeadSubmittedSuccessPage from '../components/huntingBoard.tsx/LeadSubmittedSuccessPage';
import News from '../pages/info/news/News';
import LandingPage from '../pages/info/news/LandingPage';
import BusinessProfile from '../pages/info/news/BusinessProfile';
import TeaserPreLaunch from '../pages/info/TeaserPreLaunch';
import VerifyAccountPage from '../components/auth/VerifyAccountPage';
import VerifyInvitationPage from '../pages/VerifyInvitationPage';
// import AdminSentPasswordReset from '../components/dashboard/adminDashboard/AdminSentPasswordReset';

import AddHunt from '../pages/huntingBoard/AddHunt';
import HuntCreatedSuccessPage from '../components/huntingBoard.tsx/HuntCreatedSuccessPage';
import HuntingDashboard from '../components/huntingBoard.tsx/dashboard/HuntingDashboard';
import MatchesPerHunt from '../components/huntingBoard.tsx/dashboard/MatchesPerHunt';
import AdminHuntingboard from '../components/dashboard/adminDashboard/AdminHuntingboard';
import AdminHunts from '../components/dashboard/adminDashboard/AdminHunts';
import AdminMatches from '../components/dashboard/adminDashboard/AdminMatches';
import AddBusiness from '../pages/business/AddBusiness';
import BusinessLayout from '../components/Layouts/BusinessLayout';

import Settings from '../components/business/businessSettings/Settings';
import BusinessDashboard from '../components/business/BusinessDashboard';
import BusinessProfilePage from '../components/business/BusinessProfilePage';
import BusinessPage from '../components/business/Businesspage';
import ListingsManagementPage from '../components/business/ListingsManagementPage';
import AdminManageBusinesses from '../components/dashboard/adminDashboard/AdminManageBusinesses';

import MobileSearch from '../components/main/MobileSearch';
import SquarePage from '../pages/square/SquarePage';
import SquareLayout from '../components/Layouts/SquareLayout';
import PostPage from '../components/square/PostPage';
import CommunityPage from '../pages/community/CommunityPage';
import AllResultsPage from '../pages/AllResultsPage';
import AddPost from '../pages/square/AddPost';

import SlideTransition from '../components/animation/transition/SlideTransition';
import { getSubdomain } from '../utils/subdomain';
import Unpublished from '../pages/Unpublished';

import SearchResultPage from '../pages/SearchResultPage';
import PostRegistrationPage from '../pages/PostRegistrationPage';

function Router() {
  const location = useLocation();
  const navigate = useNavigate();
  const [subdomain, setSubdomain] = useState(getSubdomain()); // Determine subdomain

  useEffect(() => {
    const currentPath = location.pathname;
    if (currentPath.includes('/api/dl')) {
      // Remove '/api/dl' from the current path
      const newPath = currentPath.replace('/api/dl', '');
      // Navigate to the new path
      navigate(newPath);
    }
  }, [location, navigate]);

  useEffect(() => {
    // This effect only runs once on mount to set the subdomain
    setSubdomain(getSubdomain());
  }, []);

  if (subdomain === 'sub') {
    return (
      <Routes>
        <Route element={<CustomLayout />}>
          <Route path="/business/:businessId/properties/:id" element={<ListingPage />} />
          <Route path="/business/:businessId/market" element={<Home />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    );
  }

  return (
    <Routes>
      {/* with whiteLabel layout */}
      <Route element={<CustomLayout />}>
        <Route path="/business/:businessId/properties/:id" element={<ListingPage />} />
        <Route path="/business/:businessId/market" element={<Home />} />
      </Route>

      {/* with Main layout */}
      <Route element={<Layout />}>
        {/* Public routes */}
        <Route path="/properties/:id" element={<ListingPage />} />
        <Route path="/properties/preview/:id" element={<PreviewListingPage />} />
        <Route path="/member/:id" element={<AgentPage />} />
        <Route path="/business/:id" element={<BusinessPage />} />
        <Route path="/huntingboard" element={<HuntingPreLaunch />} />
        <Route path="/messenger" element={<TeaserPreLaunch />} />
        <Route path="/network" element={<NetworkPreLaunch />} />
        <Route path="/forms" element={<FormsPreLaunch />} />
        <Route path="/listingguidelines" element={<ListingGuidelines />} />
        <Route path="/termsofservice" element={<TermsOfService />} />
        <Route path="/customurlpolicy" element={<CustomURLPolicy />} />
        <Route path="/contentpolicy" element={<ContentPolicy />} />
        <Route path="/news" element={<LandingPage />} />
        <Route path="/news/business-profile" element={<BusinessProfile />} />
        <Route path="/launch" element={<News />} />
        <Route path="/aboutus" element={<AboutUs />} />
        <Route path="/listing" element={<Listing />} />
        <Route path="/privacypolicy" element={<PrivacyPolicy />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/reset-password" element={<ResetPasswordPage />} />
        <Route
          path="/users/reset_password_received/:userId/:token"
          element={<UpdatePasswordPage />}
        />
        <Route path="/users/verify_account/:userId/:token" element={<VerifyAccountPage />} />
        <Route path="/invitation/:userId/:token" element={<VerifyInvitationPage />} />
        <Route path="/appointsalesrep" element={<SalesPreLaunch />} />
        <Route path="/home" element={<Home />} />

        <Route path="/community" element={<CommunityPage />} />
        <Route path="/square" element={<SquarePage />} />
        <Route path="/all" element={<AllResultsPage />} />
        <Route path="/results/:type" element={<SearchResultPage />} />
        <Route path="/square/post/:postId" element={<PostPage />} />

        <Route path="/" element={<Home />} />
      </Route>

      {/* with Help Center layout */}
      <Route element={<HelpCenterLayout />}>
        {/* Public routes */}
        <Route path="/help" element={<HelpCenterHomePage />} />
        <Route path="/help/article/:id" element={<Article />} />
      </Route>
      {/* with Learning Center layout */}
      <Route element={<LearningCenterLayout />}>
        {/* Public routes */}
        {/* <Route path="/learningcenter" element={<LearningHomePage />} /> */}
      </Route>
      {/* with user dashboard layout */}
      <Route element={<DashboardLayout />}>
        {/* signed / protected routes */}
        <Route
          path="/users/dashboard"
          element={
            <Protected>
              <SlideTransition direction={'ltr'}>
                <DashboardHomePage />
              </SlideTransition>
            </Protected>
          }
        />
        <Route
          path="/users/addbusiness"
          element={
            <Protected>
              <AddBusiness />
            </Protected>
          }
        />
        <Route
          path="/users/addpost/:type"
          element={
            <Protected>
              <AddPost />
            </Protected>
          }
        />
        <Route
          path="/users/verifyprofile"
          element={
            <Protected>
              <VerifyProfile />
            </Protected>
          }
        />
        <Route
          path="/users/deleteaccount"
          element={
            <Protected>
              <DeleteAccount />
            </Protected>
          }
        />
        <Route
          path="/users/managelistings"
          element={
            <Protected>
              <SlideTransition>
                <ManageListingPage />
              </SlideTransition>
            </Protected>
          }
        />
        <Route
          path="/users/loginandsecurity"
          element={
            <Protected>
              <SlideTransition>
                <LoginAndSecurityPage />
              </SlideTransition>
            </Protected>
          }
        />
        <Route
          path="/users/notifications"
          element={
            <Protected>
              <Notifications />
            </Protected>
          }
        />
        <Route
          path="/propertycreated/:redirectToBusiness?"
          element={
            <Protected>
              <CreatedSuccessfully />
            </Protected>
          }
        />
        <Route
          path="/users/leadsubmitted"
          element={
            <Protected>
              <LeadSubmittedSuccessPage />
            </Protected>
          }
        />
        <Route
          path="/users/huntcreated"
          element={
            <Protected>
              <HuntCreatedSuccessPage />
            </Protected>
          }
        />
        <Route
          path="/users/huntingboard/:tab?"
          element={
            <Protected>
              <SlideTransition>
                <HuntingDashboard />
              </SlideTransition>
            </Protected>
          }
        />
        <Route
          path="/users/profile"
          element={
            <Protected>
              <SlideTransition>
                <ProfilePage />
              </SlideTransition>
            </Protected>
          }
        />
        <Route
          path="/users/editlisting/:id"
          element={
            <Protected>
              <EditListingPage />
            </Protected>
          }
        />
        <Route
          path="/users/matches/hunt/:id"
          element={
            <Protected>
              <MatchesPerHunt />
            </Protected>
          }
        />

        <Route
          path="/comingsoon"
          element={
            <Protected>
              <ComingSoonPage />
            </Protected>
          }
        />
        {/* admin */}
        <Route
          path="/admin/dashboard"
          element={
            <AdminProtected>
              <AdminDashboardHomePage />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/listings"
          element={
            <AdminProtected>
              <AdminManageListings />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/editlisting/:id"
          element={
            <AdminProtected>
              <AdminEditListing />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/users/"
          element={
            <AdminProtected>
              <AdminManageUsers />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/square/"
          element={
            <AdminProtected>
              <AdminManagePosts />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/huntingboard/"
          element={
            <AdminProtected>
              <AdminHuntingboard />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/hunts/"
          element={
            <AdminProtected>
              <AdminHunts />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/businesses/"
          element={
            <AdminProtected>
              <AdminManageBusinesses />
            </AdminProtected>
          }
        />
        <Route
          path="/admin/matches/"
          element={
            <AdminProtected>
              <AdminMatches />
            </AdminProtected>
          }
        />
        {/* end of dashboard */}
      </Route>

      {/* with business layout */}
      <Route element={<BusinessLayout />}>
        <Route
          path="/business/settings"
          element={
            <BusinessProtected>
              <SlideTransition>
                <Settings />
              </SlideTransition>
            </BusinessProtected>
          }
        />
        <Route
          path="/business/managelistings"
          element={
            <BusinessProtected>
              <SlideTransition>
                <ListingsManagementPage />
              </SlideTransition>
            </BusinessProtected>
          }
        />
        <Route
          path="/business/dashboard"
          element={
            <BusinessProtected>
              <SlideTransition direction={'ltr'}>
                <BusinessDashboard />
              </SlideTransition>
            </BusinessProtected>
          }
        />
        <Route
          path="/business/profile"
          element={
            <BusinessProtected>
              <SlideTransition>
                <BusinessProfilePage />
              </SlideTransition>
            </BusinessProtected>
          }
        />
      </Route>

      {/* with square layout */}
      <Route element={<SquareLayout />}>
        {/* <Route path="/square" element={<SquarePage />} />
        <Route path="/square/post/:postId" element={<PostPage />} /> */}
      </Route>

      {/* without layout */}
      <Route
        path="/addproperty/:isBusiness?"
        element={
          <Protected>
            <AddProperty />
          </Protected>
        }
      />
      <Route
        path="/users/addhunt/:type?"
        element={
          <Protected>
            <SlideTransition>
              <AddHunt />
            </SlideTransition>
          </Protected>
        }
      />
      <Route
        path="/addproperty/:id"
        element={
          <Protected>
            <AddProperty />
          </Protected>
        }
      />
      <Route path="/search/" element={<MobileSearch />} />
      <Route path="/getting-started" element={<PostRegistrationPage />} />
      <Route path="/unpublished" element={<Unpublished />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
  );
}

export default Router;
